









































































































































































































































































































































import { defineComponent, computed, ref, watch } from '@vue/composition-api';
import Chart from 'chart.js/auto';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';
import ReportItem from '@/components/ReportItem.vue';
import ReportFluctuationArrow from '@/components/ReportFluctuationArrow.vue';
import report from '@/composition/report';
import { pointColors, basicColors } from '@/util/chartColor';

export default defineComponent({
  name: 'Report',
  components: {
    FcRoleLoading,
    FcRoleDeny,
    ReportItem,
    ReportFluctuationArrow,
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('report'));
    const isLottery: boolean = process.env.VUE_APP_IS_LOTTERY === 'true';

    // データ取得
    report.getReportData();

    const getFluctuationStatus = (currentValue: number, prevValue: number) => {
      const diff = currentValue - prevValue;
      return diff === 0 ? 'stay' : 0 < diff ? 'rise' : 'fall';
    };
    const getDiff = (currentValue: number, prevValue: number) => {
      const diff = currentValue - prevValue;
      return diff === 0 ? '±0' : 0 < diff ? `+${diff.toLocaleString()}` : diff.toLocaleString();
    };

    // 会員数
    const userCounts = computed(() => {
      const userCounts = report.userCounts;
      if (!userCounts.length) return null;

      const todayUserCounts = userCounts.slice(-1)[0];
      const yesterdayUserCounts = 1 < userCounts.length ? userCounts.slice(-2)[0] : null;

      const thisYear = report.dateString.slice(0, 4);
      const thisMonth = report.dateString.slice(5, 7);
      const thisMonthUserCounts = userCounts.filter((item) => item.displayDate.startsWith(`${thisYear}/${thisMonth}`));
      const prevMonthUserCounts = userCounts.filter((item) =>
        item.displayDate.startsWith(
          `${thisMonth === '01' ? Number(thisYear) - 1 : thisYear}/${
            thisMonth === '01' ? '12' : (Number(thisMonth) - 1).toString().padStart(2, '0')
          }`
        )
      );
      const thisMonthNewCounts = thisMonthUserCounts.reduce((acc, current) => acc + current.counts.new, 0);
      const prevMonthNewCounts = prevMonthUserCounts.length
        ? prevMonthUserCounts.reduce((acc, current) => acc + current.counts.new, 0)
        : 0;
      const thisMonthLeaveCounts = thisMonthUserCounts.reduce((acc, current) => acc + current.counts.leave, 0);
      const prevMonthLeaveCounts = prevMonthUserCounts.length
        ? prevMonthUserCounts.reduce((acc, current) => acc + current.counts.leave, 0)
        : 0;

      return {
        today: {
          total: todayUserCounts.counts.total.toLocaleString(),
          active: todayUserCounts.counts.active.toLocaleString(),
          deleted: todayUserCounts.counts.deleted.toLocaleString(),
          new: todayUserCounts.counts.new.toLocaleString(),
          newDiff: getDiff(todayUserCounts.counts.new, yesterdayUserCounts ? yesterdayUserCounts.counts.new : 0),
          newFluctuation: getFluctuationStatus(
            todayUserCounts.counts.new,
            yesterdayUserCounts ? yesterdayUserCounts.counts.new : 0
          ),
          leave: todayUserCounts.counts.leave.toLocaleString(),
          leaveDiff: getDiff(todayUserCounts.counts.leave, yesterdayUserCounts ? yesterdayUserCounts.counts.leave : 0),
          leaveFluctuation: getFluctuationStatus(
            todayUserCounts.counts.leave,
            yesterdayUserCounts ? yesterdayUserCounts.counts.leave : 0
          ),
        },
        thisMonth: {
          new: thisMonthNewCounts.toLocaleString(),
          newDiff: getDiff(thisMonthNewCounts, prevMonthNewCounts),
          newFluctuation: getFluctuationStatus(thisMonthNewCounts, prevMonthNewCounts),
          leave: thisMonthLeaveCounts.toLocaleString(),
          leaveDiff: getDiff(thisMonthLeaveCounts, prevMonthLeaveCounts),
          leaveFluctuation: getFluctuationStatus(thisMonthLeaveCounts, prevMonthLeaveCounts),
        },
      };
    });

    // 売上
    const salesCounts = computed(() => {
      // プラン・個別課金、くじに登録がない（TODO: くじの判定条件をくじ一覧を取得したら、作成されたくじがあるかに変える）
      if (!report.hasSubscriptionPlans && !report.hasProducts && !isLottery) return null;

      const salesPlanInfos = report.salesInfos.plans;
      const salesProductInfos = report.salesInfos.products;
      const salesLotteryInfos = report.salesInfos.lotteries;
      if (!salesPlanInfos.length && !salesProductInfos.length && !salesLotteryInfos.length) return null;

      const date29daysAgo = report.date29daysAgo;
      const current30daysPlanSales = report.hasSubscriptionPlans
        ? salesPlanInfos.reduce((acc, current) => {
            if (date29daysAgo <= current.date) return acc + current.totalPrice;
            return acc;
          }, 0)
        : 0;
      const current30daysProductSales = report.hasProducts
        ? salesProductInfos.reduce((acc, current) => {
            if (date29daysAgo <= current.date) return acc + current.totalPrice;
            return acc;
          }, 0)
        : 0;
      const current30daysLotterySales = isLottery
      ? salesLotteryInfos.reduce((acc, current) => {
          if (date29daysAgo <= current.date) return acc + current.totalPrice;
          return acc;
        }, 0)
      : 0;

      const todayPlanSales =
        report.hasSubscriptionPlans && salesPlanInfos.length ? salesPlanInfos.slice(-1)[0].totalPrice : 0;
      const todayProductSales = salesProductInfos.length ? salesProductInfos.slice(-1)[0].totalPrice : 0;
      const todayLotterySales = salesLotteryInfos.length ? salesLotteryInfos.slice(-1)[0].totalPrice : 0;
      const yesterdayPlanSales =
        report.hasSubscriptionPlans && 1 < salesPlanInfos.length ? salesPlanInfos.slice(-2)[0].totalPrice : 0;
      const yesterdayProductSales = 1 < salesProductInfos.length ? salesProductInfos.slice(-2)[0].totalPrice : 0;
      const yesterdayLotterySales = 1 < salesLotteryInfos.length ? salesLotteryInfos.slice(-2)[0].totalPrice : 0;

      const thisYear = report.dateString.slice(0, 4);
      const thisMonth = report.dateString.slice(5, 7);
      const thisMonthPlanSales = report.hasSubscriptionPlans
        ? salesPlanInfos
            .filter((item) => item.displayDate.startsWith(`${thisYear}/${thisMonth}`))
            .reduce((acc, current) => acc + current.totalPrice, 0)
        : 0;
      const thisMonthProductSales = salesProductInfos
        .filter((item) => item.displayDate.startsWith(`${thisYear}/${thisMonth}`))
        .reduce((acc, current) => acc + current.totalPrice, 0);
      const thisMonthLotterySales = salesLotteryInfos
        .filter((item) => item.displayDate.startsWith(`${thisYear}/${thisMonth}`))
        .reduce((acc, current) => acc + current.totalPrice, 0);
      const prevMonthPlanSales = report.hasSubscriptionPlans
        ? salesPlanInfos
            .filter((item) =>
              item.displayDate.startsWith(
                `${thisMonth === '01' ? Number(thisYear) - 1 : thisYear}/${
                  thisMonth === '01' ? '12' : (Number(thisMonth) - 1).toString().padStart(2, '0')
                }`
              )
            )
            .reduce((acc, current) => acc + current.totalPrice, 0)
        : 0;
      const prevMonthProductSales = salesProductInfos
        .filter((item) =>
          item.displayDate.startsWith(
            `${thisMonth === '01' ? Number(thisYear) - 1 : thisYear}/${
              thisMonth === '01' ? '12' : (Number(thisMonth) - 1).toString().padStart(2, '0')
            }`
          )
        )
        .reduce((acc, current) => acc + current.totalPrice, 0);
      const prevMonthLotterySales = isLottery
        ? salesLotteryInfos
            .filter((item) =>
              item.displayDate.startsWith(
                `${thisMonth === '01' ? Number(thisYear) - 1 : thisYear}/${
                  thisMonth === '01' ? '12' : (Number(thisMonth) - 1).toString().padStart(2, '0')
                }`
              )
            )
            .reduce((acc, current) => acc + current.totalPrice, 0)
        : 0;

      return {
        current30days: {
          sales: (current30daysPlanSales + current30daysProductSales + current30daysLotterySales).toLocaleString(),
          planSales: current30daysPlanSales.toLocaleString(),
          productSales: current30daysProductSales.toLocaleString(),
          lotterySales: current30daysLotterySales.toLocaleString(),
        },
        today: {
          planSales: todayPlanSales.toLocaleString(),
          planSalesDiff: getDiff(todayPlanSales, yesterdayPlanSales),
          planFluctuation: getFluctuationStatus(todayPlanSales, yesterdayPlanSales),
          productSales: todayProductSales.toLocaleString(),
          productSalesDiff: getDiff(todayProductSales, yesterdayProductSales),
          productFluctuation: getFluctuationStatus(todayProductSales, yesterdayProductSales),
          lotterySales: todayLotterySales.toLocaleString(),
          lotterySalesDiff: getDiff(todayLotterySales, yesterdayLotterySales),
          lotteryFluctuation: getFluctuationStatus(todayLotterySales, yesterdayLotterySales),
        },
        thisMonth: {
          planSales: thisMonthPlanSales.toLocaleString(),
          planSalesDiff: getDiff(thisMonthPlanSales, prevMonthPlanSales),
          planFluctuation: getFluctuationStatus(thisMonthPlanSales, prevMonthPlanSales),
          productSales: thisMonthProductSales.toLocaleString(),
          productSalesDiff: getDiff(thisMonthProductSales, prevMonthProductSales),
          productFluctuation: getFluctuationStatus(thisMonthProductSales, prevMonthProductSales),
          lotterySales: thisMonthLotterySales.toLocaleString(),
          lotterySalesDiff: getDiff(thisMonthLotterySales, prevMonthLotterySales),
          lotteryFluctuation: getFluctuationStatus(thisMonthLotterySales, prevMonthLotterySales),
        },
      };
    });

    // 会員情報グラフ
    const paidMembershipGenderChartCanvas = ref<HTMLCanvasElement | null>(null);
    const paidMembershipAgeChartCanvas = ref<HTMLCanvasElement | null>(null);
    const paidMembershipPrefectureChartCanvas = ref<HTMLCanvasElement | null>(null);
    const allMembershipGenderChartCanvas = ref<HTMLCanvasElement | null>(null);
    const allMembershipAgeChartCanvas = ref<HTMLCanvasElement | null>(null);
    const allMembershipPrefectureChartCanvas = ref<HTMLCanvasElement | null>(null);

    const displayChart = (
      canvas: HTMLCanvasElement,
      labels: string[],
      data: number[],
      backgroundColor: string[],
      labelContainer: string,
      totalCount: number
    ) => {
      new Chart(canvas, {
        type: 'doughnut',
        data: {
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: backgroundColor,
              hoverOffset: 2,
              borderWidth: 0,
            },
          ],
        },
        plugins: [
          {
            id: 'htmllabels',
            // https://www.chartjs.org/docs/3.9.1/developers/plugins.html
            afterUpdate(chart: any, _: any, options: any) {
              const labelsContainer = document.getElementById(options.containerId);
              if (!labelsContainer) return;

              const items: {
                fillStyle: string;
                text: string;
              }[] = chart.options.plugins.legend.labels.generateLabels(chart);
              const htmlString = items.reduce((acc, current) => {
                const html = `
              <div class="d-flex align-center" style="font-size: 12px;">
                <div class="mr-1" style="background-color: ${current.fillStyle}; width: 10px; height: 10px;"></div>
                <div>${current.text}</div>
              </div>
            `;
                return acc + html;
              }, '');
              labelsContainer.innerHTML = htmlString;
            },
          },
        ],
        options: {
          plugins: {
            htmllabels: {
              containerId: labelContainer,
            },
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: function(tooltipItem: {
                  label: string;
                  formattedValue: string;
                  dataIndex: number;
                  dataset: { data: number[] };
                }) {
                  const rate = Math.round((tooltipItem.dataset.data[tooltipItem.dataIndex] / totalCount) * 1000) / 10; // 小数第二位で四捨五入、小数第一位まで表示
                  return ` ${tooltipItem.label} : ${tooltipItem.formattedValue}人（${rate}%）`;
                },
              },
            },
          },
          layout: {
            padding: 5,
          },
        },
      });
    };
    const createGenderChart = (isPaidMembership: boolean) => {
      if (
        (isPaidMembership && !paidMembershipGenderChartCanvas.value) ||
        (!isPaidMembership && !allMembershipGenderChartCanvas.value) ||
        report.isLoading
      )
        return;
      const labels = ['男性', '女性', '回答しない', '-'];
      const filteredLabels: string[] = [];
      const userData = isPaidMembership ? report.paidMembershipUsers : report.activeUsers;
      const data: number[] = [];
      labels.forEach((label) => {
        const counts = userData.filter((item) => item.gender === label).length;
        if (counts) {
          filteredLabels.push(label);
          data.push(counts);
        }
      });
      const backgroundColor: string[] = filteredLabels.map((label) => {
        if (label === '男性') return pointColors.blue;
        if (label === '女性') return pointColors.red;
        if (label === '回答しない') return pointColors.grey;
        else return pointColors.lightgrey;
      });

      displayChart(
        isPaidMembership
          ? (paidMembershipGenderChartCanvas.value as HTMLCanvasElement)
          : (allMembershipGenderChartCanvas.value as HTMLCanvasElement),
        filteredLabels,
        data,
        backgroundColor,
        isPaidMembership ? 'paidmembership-gender-labels' : 'gender-labels',
        userData.length
      );
    };
    const createAgeChart = (isPaidMembership: boolean) => {
      if (
        (isPaidMembership && !paidMembershipAgeChartCanvas.value) ||
        (!isPaidMembership && !allMembershipAgeChartCanvas.value) ||
        report.isLoading
      )
        return;

      const labels = ['10代', '20代', '30代', '40代', '50代', '60代', '70代以上', 'その他', '-'];
      const userData = isPaidMembership ? report.paidMembershipUsers : report.activeUsers;
      const data = userData.map((item) => item.ageGroup);

      const filteredLabels: string[] = [];
      const filteredData: number[] = [];
      labels.forEach((item) => {
        const count = data.filter((age) => age === item).length;
        if (count) {
          filteredLabels.push(item);
          filteredData.push(count);
        }
      });

      displayChart(
        isPaidMembership
          ? (paidMembershipAgeChartCanvas.value as HTMLCanvasElement)
          : (allMembershipAgeChartCanvas.value as HTMLCanvasElement),
        filteredLabels,
        filteredData,
        basicColors,
        isPaidMembership ? 'paidmembership-age-labels' : 'age-labels',
        userData.length
      );
    };
    const createPrefectureChart = (isPaidMembership: boolean) => {
      if (!allMembershipPrefectureChartCanvas.value || report.isLoading) return;

      const userData = isPaidMembership ? report.paidMembershipUsers : report.activeUsers;
      const prefectures = [...new Set(userData.map((user) => user.prefecture))];
      const data = prefectures
        .map((prefecture) => ({
          label: prefecture,
          counts: userData.filter((user) => user.prefecture === prefecture).length,
        }))
        .sort((a, b) => b.counts - a.counts);

      const filteredLabels = data.map((item) => item.label);
      const filteredData = data.map((item) => item.counts);

      displayChart(
        isPaidMembership
          ? (paidMembershipPrefectureChartCanvas.value as HTMLCanvasElement)
          : (allMembershipPrefectureChartCanvas.value as HTMLCanvasElement),
        filteredLabels,
        filteredData,
        basicColors,
        isPaidMembership ? 'paidmembership-prefecture-labels' : 'prefecture-labels',
        userData.length
      );
    };

    // グラフの描画
    watch(
      () => [report.isLoading, paidMembershipGenderChartCanvas.value],
      () => createGenderChart(true)
    );
    watch(
      () => [report.isLoading, paidMembershipAgeChartCanvas.value],
      () => createAgeChart(true)
    );
    watch(
      () => [report.isLoading, paidMembershipPrefectureChartCanvas.value],
      () => createPrefectureChart(true)
    );
    watch(
      () => [report.isLoading, allMembershipGenderChartCanvas.value],
      () => createGenderChart(false)
    );
    watch(
      () => [report.isLoading, allMembershipAgeChartCanvas.value],
      () => createAgeChart(false)
    );
    watch(
      () => [report.isLoading, allMembershipPrefectureChartCanvas.value],
      () => createPrefectureChart(false)
    );

    return {
      pageTitle: 'レポート',
      myRoleSettings,
      report,
      userCounts,
      salesCounts,
      allMembershipGenderChartCanvas,
      allMembershipAgeChartCanvas,
      allMembershipPrefectureChartCanvas,
      paidMembershipGenderChartCanvas,
      paidMembershipAgeChartCanvas,
      paidMembershipPrefectureChartCanvas,
      isLottery,
    };
  },
});
